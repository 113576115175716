@import "mixins";


@font-face {
  font-family: "GothamBook";
  src: url("./assets/fonts/Gotham Rounded Book.woff");
}

$fontPrimary: "GothamBook";

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;


  // Fix inertial scroll on mobile
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;

  @include respond(tabLand) { // width < 1200
    font-size: 56.25%; // 9px
  }

  @include respond(tabPort) { // width < 900
    font-size: 50%; // 8px
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: GothamBook;
  width: 100%;
  overflow-x: hidden;
}


.App {
  width: 100vw;
  height: 100%;
  padding-bottom: 27rem;
  position: relative;
}

.left-side {
  @include respond(phone) {
    padding-top: 5rem;
  }

}


.right-side {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: white;
  font-size: 2rem;
  letter-spacing: 2.4px;
  @include respond(phone) {
    display: none;

  }
}

.billboard {

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    @include respond(tabPort) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;

    }
  }

  &-img-wrapper {
    display: flex;
    max-width: 97.3rem;
    margin-right: 10rem;
    min-width: 0;
    max-height: 100%;
    justify-content: center;

    @include respond(tabPort) {
      margin-right: 0;
    }

  }

  &-img {
      width: 100%;
      height: 100%;
      min-width: 0;

      @include respond(tabPort) {
        margin-bottom: 10rem;

      }

  }

  &-text {
    margin-left: 15rem;
    color: white;
    font-family: $fontPrimary;
    font-size: 4.8rem;
    width: 45rem;
    flex-shrink: 0;
    @include respond(tabPort) {
      margin-left: 0;
      margin-bottom: 10rem;
      min-width: 0;
      flex-wrap: wrap;
    }


  }
}





.Dotz {
  position: absolute;
  margin-left: 0;
  transform: translate(45.5rem, -22.5rem);
  width: 67vw;
}

.portfolio {
  &-clients {
    margin-top: 11rem;
    width: 120rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min-content, 300px));
    grid-row-gap: 10rem;
    grid-column-gap: 5rem;
    justify-content: center;
    }

  &-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 85%;
    }
  }
}



// Burger Menu shit
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 25px;
  right: 18px;
  top: 36px;
  display: none;

  @include respond(phone) {
    display: block;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ddd;
  height: 8% !important;
  width: 80% !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
  height: 25px !important;
}

/* General sidebar styles */
.bm-menu {
  background: radial-gradient(circle at center, #ffad6f 0%, #ff5f6f 84%, #ff5a6f 100%);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  color: #fff;
  font-family: $fontPrimary;
  font-size: 3.5rem;
  text-decoration: none;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.burger-menu-logo {
  margin: 4rem 0;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* ---- reset ---- */
canvas {
  display: block;
  vertical-align: bottom;
} /* ---- particles.js container ---- */
#particles-js {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
} /* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.count-particles {
  border-radius: 0 0 3px 3px;
}
